//import Bootstrap from 'bootstrap-sass/assets/javascripts/bootstrap';

var tasty = {
	offsetTop: 90+32
};


(function($) {
     $.fn.goTo = function() {
        $(this).addClass('target-element');
		$('html, body').animate({
	        scrollTop: $(this).offset().top - tasty.offsetTop
	    }, 800, 
	    function () {
		    setTimeout(function(){
			  $('.target-element').removeClass('target-element');
			}, 400);
	    });
	    return this; // for chaining...
     }
})(jQuery);
	
	
	
(function($, exports) {
	
	
	
			
	if($('html.js > body.bg-video').length > 0 ){
		/*
			Support for fixed main navigation motion, and hiding on front video splash
		*/
		var lastScrollY = 0,
		$header = $('.js .site-header'),
		scrollLimit = $header.outerHeight()- 80,
		splshnavpos = 0,
		checkNavfix = function(){
			lastScrollY = $(window).scrollTop();
			if( lastScrollY > splshnavpos ) {
				$header.addClass('fixit');
			}else{
				$header.removeClass('fixit');
			}
		};


		$(window).on("resize, load", function (){
			var navpos = $('.nav-splash-video').offset();
			splshnavpos = navpos.top;
			//console.log(splshnavpos);
		});
		
		
		$(window).scroll(checkNavfix);
		
	}
	
	
	$(window).on("resize, load", function (){
		$('.single-venue-hub header.entry-header > .booking-box, .single-digital-hub header.entry-header > .booking-box').affix({
		  offset: {
		    top: function () {
			    var offset = $('.content-sidebar-wrap').offset();
			    
				return (this.top = offset.top - tasty.offsetTop);
		    },
		    bottom: function () {
			    var offset = $('.insignia').offset();
			    console.log($( document ).height() - offset.top);
			    return (this.bottom = $( document ).height() - offset.top )
		    }
		  }
		})
	});
	$('.back-to-top').on('click', function(event){
		event.preventDefault();
		$('body,html').animate({
			scrollTop: 0 ,
		 	}, 700
		);
	});
	
/*
	if($('.gallery .row #toggle-gallery').length > 0){
		const $toggleBlock = 	$('.gallery .row #toggle-gallery');
		$toggleBlock.parent().append('<div class="col-xs-16"><a class="btn btn-link" role="button" data-toggle="collapse" href="#toggle-gallery" aria-expanded="false" aria-controls="toggle-gallery">Show more images</a></div>')
		.on('shown.bs.collapse', function () {
			$toggleBlock.parent().find('a.btn-link').text('Hide more images');
		})
		.on('hidden.bs.collapse', function () {
			$toggleBlock.parent().find('a.btn-link').text('Show more images');
		})
		.on('hide.bs.collapse', function () {
			$('#toggle-gallery').goTo();
		});


	}
*/
	$('.toggle-block').each(function() {
		var toggleID = $(this).attr('id');
		if(toggleID) {
			var toggleStr = (toggleID === "toggle-gallery") ? ' images' : '';
			var rowClass = (toggleID === "toggle-gallery") ? 'col-xs-16' : 'toggle-button';
			$(this).parent().append('<div class="'+rowClass+'"><a class="btn btn-link" role="button" data-toggle="collapse" href="#'+toggleID+'" aria-expanded="false" aria-controls="'+toggleID+'">Show more'+toggleStr+'</a></div>')
			.on('shown.bs.collapse', function () {
				$(this).parent().find('a.btn-link').text('Hide more'+toggleStr);
			})
			.on('hidden.bs.collapse', function () {
				$(this).parent().find('a.btn-link').text('Show more'+toggleStr);
			})
			.on('hide.bs.collapse', function () {
				$('#'+toggleID).goTo();
			});
		}
		
	});
	
/*
	if( $("#children").length==1 ){
		new jQueryCollapse($("#children"), {
	        open: function() {
	            this.slideDown(150);
	        },
	        close: function() {
	            this.slideUp(150);
	        },
			accordion: true,
			persist: true
	    });
	}
*/
	
   	// if($('.clicker > a').length > 0){
	 //
	 // 	$("body").append("<div id=\"menu-overlay\"></div>"); //overlay closes submenu when clicking out...
	 //
		// $('.clicker > a').on("click", function (e) {
		// 	e.preventDefault();
		// 	$(this).parent().find(".sub-menu").toggle();
		// 	$(this).parent().siblings().find(".sub-menu").hide();
	 //
		// 	if ($(".sub-menu:visible").length === 0) {
		// 		$("#menu-overlay").hide();
		// 	} else {
		// 		$("#menu-overlay").show();
		// 		$(this).addClass('clicked');
		// 	}
		// });
		//
		// $("#menu-overlay").on("click", function () {
		// 	$(".sub-menu").hide();
		// 	$('.clicker > a').removeClass('clicked');
		// 	$(this).hide();
		// });
 	// }
   
})(window.jQuery, window);


