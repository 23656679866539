// Stylesheets
import './sass/style.scss';

// import jQuery from 'jquery';

import 'bootstrap-sass/assets/javascripts/bootstrap';

import './scripts/main';
import './scripts/customGoogleMaps';
import './scripts/cookie';
import './scripts/external-links';

import FastClick from 'fastclick';
FastClick.attach(document.body);


// export for others scripts to use
window.$ = window.jQuery = $ = jQuery;


require("imports-loader?$=jquery!slicknav/dist/jquery.slicknav.js");
require("imports-loader?$=jquery!@fancyapps/fancybox/dist/jquery.fancybox.js");
// import '@fancyapps/fancybox/dist/jquery.fancybox.css';
//import 'slicknav/dist/slicknav.css';

/*
$('#genesis-nav-primary ul.menu-primary').slicknav({
		'allowParentLinks': true, 
		'closeOnClick': true
	});
*/

$('#genesis-nav-primary').slicknav({
	'prependTo': '#mobile-menu', 
	'allowParentLinks': true,
	'closedSymbol': '<i class="fa fa-chevron-down" aria-hidden="true"></i>', 
	'openedSymbol': '<i class="fa fa-times" aria-hidden="true"></i>'
});


function menuOverflow(){
	// if window is bigger than mobile menu breakpoint, ensure all .sub-menu’s are max-heighted
	let bp = 992;
	let $submenus = $('.menu-primary ul.sub-menu');
	let ww = $(window).width();
	if(ww >= bp){
		let headerH = $('header.site-header').height();
		if($('#wpadminbar').length > 0){
			headerH += $('#wpadminbar').height();
		}
		$submenus.css("max-height", $(window).height()-headerH);
	}else{
		$submenus.css("max-height", 'auto');
		
	}
}
$(document).ready(function(){  
    menuOverflow();
    $(window).resize(function () {
	    menuOverflow();
	});
});



$().fancybox({
    selector : '.wp-block-gallery.lightbox > .blocks-gallery-item a'
});

// customising the ultimate member profile form
$('.um-profile-body.main .um-row').filter(function () {
	let ref = this.className.match(/\b_um_row_\d/);
    if( ref !== null){
	    $(this).attr({
		    'id': ref[0],
		    'role': 'tabpanel',
		    'aria-labelledby': ref[0]+'-tab'
		});
		
	}
	return ref;
}).addClass(function () {
	let number = this.className.match(/\d/);
	let string = 'tab-pane';

	if( number[0] === '1'){
		string += ' in active';
	}

	return string;
});

// $('.um-profile.um-viewing .um-profile-body.main #profile-tabs > li.nav-item').each(function( index ) {
//     // hide tabs with no related panels in um-viewing mode
//     //console.log( index + ": " + $( this ).text() );
//     let rowid = $(this).find('a').attr('aria-controls');
//     if($('.um-row.tab-pane#'+rowid+' .um-field').length === 0){
//         $(this).hide();
//     }
//
// });

$('.um-profile-body.main #profile-tabs a.nav-link').not( '#_um_row_message-tab' ).on('click', function (e) {
	  e.preventDefault()
	  $(this).tab('show')
});

$('#perk-locations').on('change load', function (e) {
	var $optionSelected = $("option:selected", this);
	$optionSelected.tab('show');
});

/*

$('.wp-block-gallery.lightbox .blocks-gallery-item').each( function( index ) {
	console.log( index + ": " + $( this ).text() );
	let caption = '';
	if($( this ).children('figcaption').length > 0) {
		caption = $( this ).children('figcaption').text();
	}
	$( this ).children('a').fancybox();
});
*/
